.projects__card {
  display: flex;
  width: 100%;
  border-radius: 25px;
  text-align: center;
  opacity: 0.8;
  padding: 10px;
}

.project__cardExtended {
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: center;
  width: 100%;
  height: 100%;
}
.project__cardExtendedFooter {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
}

.project__cardExtendedFooterButton {
  border-radius: 10px;
  padding: 8px 10px;
  margin: 10px 15px;
  background-color: var(--text-stylish);
  font-size: medium;
  font-weight: 600;
  color: var(--bg-normal);
  border: none;
  transition: opacity 200ms ease-in-out;
  opacity: 0.8;
}
.project__cardExtendedFooterButton:hover {
  opacity: 1;
  cursor: pointer;
}

.left {
  display: flex;
  flex: 1;
  padding-left: 20px;
  align-items: center;
  justify-content: flex-start;
}
.projects__description {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: var(--font-stylish);
  font-weight: 500;
  padding: 10px;
  font-size: 2rem;
  font-style: bold;
  color: var(--text-normal);
}
.projects__name {
  font-size: 20px;
  text-align: left;
}
.projects__arrow {
  margin: 5px;
  transition: transform 200ms ease-in-out;
}

.projects__arrow.active {
  transform: rotate(180deg);
}

.projects__tech {
  display: flex;
  justify-content: flex-start;
}
.projects__tech > li {
  display: inline-block;
  object-fit: contain;
  width: fit-content;
  border-radius: 15px;
  padding: 4px 6px;
  font-size: 12px;
}
.project__cardImageTextDivider {
  margin: 10px 0;
}

.projects__tech > li:last-child {
  padding-right: 0;
}
.projects__card:hover {
  opacity: 1;
  cursor: pointer;
}
.project__cardImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  width: 90%;
  position: relative;
  background-color: lightgrey;
}
.project__cardImage {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  max-height: 300px;
  object-fit: contain;
}
.project__cardImage--null {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  border-radius: 15px;
  background-color: rgb(0, 0, 0);
}

.project__cardImageOverlay {
  opacity: 0;
  position: absolute;
  bottom: 15px;
  width: fit-content;
  border-radius: 10px;
  padding: 5px 10px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  font-size: medium;
  color: white;
}

.project__cardImageText {
  opacity: 0;
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  padding: 15px;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.95);
  cursor: pointer;
  font-size: clamp(15px, 2vw, 18px);
  color: white;
  overflow-y: scroll;
}
.right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* testing */
  padding-right: 20px;
}
.right__link {
  display: flex;
  justify-content: center;
  padding: 8px;
  border-radius: 20px;
}
.right__link:not(:last-child) {
  margin-right: 15px;
}

.right__link:hover {
  background-color: var(--bg-normal);
}
.right__icon {
  color: var(--text-normal);
}

.project__cardImageOverlay.active {
  opacity: 1;
}
.project__cardImageText.active {
  opacity: 1;
}
.projects__cardContainer {
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.projects__cardContainer:hover {
  opacity: 1;
  background-color: var(--shadow-normal);
}

.projects__techIcon {
  display: flex;
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

@media (max-width: 1024px) {
  .projects {
    flex: none;
    height: 100%;
  }
  .left {
    padding-left: 5px;
  }
  .right {
    padding-right: 0px;
  }

  .projects__description {
    padding: 0;
  }
  .projects__description > span {
    font-size: 50%;
  }
  .project__cardExtendedFooter {
    padding: 5px;
  }
  .projects__description > a {
    font-size: 80%;
    white-space: nowrap;
    font-size: 2.5rem;
    font-family: var(--font-stylish);
  }
  .projects__techIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  .projects__name {
    font-size: 18px;
  }

  .project__cardImageContainer {
  }
  .project__cardImage {
    max-height: 300px;
    /* test */
  }
}
