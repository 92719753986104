.projects__container {
  display: none;
  flex: 1;
  height: 85%;
  padding: 3vw;

  /* testing- keep overflow? */
  /* overflow: scroll; */
}

.projects__container.active {
  display: flex;
}

.projects {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}

.projects__image {
  height: 100px;
  width: 100px;
}

.projects__title {
  font-size: 30px;
}
.projects__title > h1 {
  text-align: end;
}

.projects__overview {
  font-family: var(--font-normal);
  font-size: medium;
  padding: 0 0 15px 0;
}
.projects__divider {
  display: none;
}
.projects__divider:not(:last-child) {
  width: 100%;
  border: solid 1px var(--text-stylish);
  display: block;
}

@media (max-width: 1024px) {
  .projects__container {
    display: flex;
    width: 100%;
    overflow: visible;
    background-color: var(--bg-shade);
    padding: 6vw;
    height: fit-content;
  }
  .projects {
    width: 100%;
    overflow-y: initial;
  }
  .projects__divider:not(:last-child) {
    margin: 3px auto;
  }
}
