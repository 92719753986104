@import url("https://fonts.googleapis.com/css2?family=Playfair+Display&family=Urbanist&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz@10..48&family=Roboto+Slab:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap");
:root {
  font-size: 16px;
  font-family: var(--font-stylish);
  font-weight: 400;
  --font-stylish: "Poppins", sans-serif;
  --font-normal: "Poppins", sans-serif;
  --text-stylish: #d65656;
  --text-normal: #252525;
  --bg-normal: #2e4dac;
  --bg-normal2: #3553ac;
  --bg-stylish: #ff4800;
  text-decoration: none;
  --bg-shade: rgba(43, 56, 97, 0.678);
}

.dark {
  --text-stylish: rgb(255, 244, 125);
  --text-normal: #fffaf8;
  --shadow-normal: rgba(255, 255, 255, 0.075);
  --bg-normal: #02041b;
  --bg-normal2: #05093cf4;
  --bg-stylish: #fffaf8;
  --bg-shade: rgba(255, 255, 255, 0.073);
}

.light {
  --text-stylish: #ff5959;
  --text-normal: rgb(0, 0, 0);
  --shadow-normal: rgba(0, 0, 0, 0.065);
  --bg-normal: rgb(253, 253, 253);
  --bg-normal2: rgb(255, 234, 234);
  --bg-stylish: #1e5be0;
  --bg-shade: rgba(102, 102, 102, 0.073);

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
*::-webkit-scrollbar {
  width: 0.2rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--text-stylish);

}

a,
a:visited {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-normal);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 3rem;
  font-family: var(--font-stylish);
  font-weight: 600;
  color: var(--text-normal);
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 0.75rem;
}

ul {
  list-style-type: none;
}

.desktop-only {
  display: flex;
}

.mobile-only {
  display: none;
}

@media (max-width: 1024px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: flex;
  }
  *::-webkit-scrollbar {
    display: none;
  }
}
