.app__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--text-normal);
  background: linear-gradient(270deg, var(--bg-normal), var(--bg-normal2));
  background-size: 400% 400%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.app {
  display: flex;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .app__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  .app {
    flex-direction: column;
    overflow-y: scroll;
    justify-content: flex-start;
  }
 
}
