a {
  transition: all 200ms ease-in-out;
  border-bottom: solid 1px transparent;
}

.header {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3vw;
  height: 85%;
}

.header__intro {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.header__name {
  margin-bottom: 6px;
}
.header__title {
  margin-left: 5px;
}
.header__description {
  margin: 10px 0;
  margin-left: 5px;
}

.header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-family: "Leckerli One", cursive;
  display: none;
  width: 40px;
  height: 40px;
}
.header__menu {
  display: none;
  width: 40px;
  height: 40px;
  fill: var(--text-normal);
}

.nav {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  min-width: fit-content;
  font-size: 20px;
  font-weight: 500;
  font-family: var(--font-normal);
}
.nav__item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
  width: fit-content;
  transition: transform 500ms ease-in-out;
  cursor: pointer;
}
.nav__item:not(.active):hover {
  color: var(--text-stylish);
  transform: translateX(10px);
}

.nav__item.active {
  color: var(--text-stylish);
}
.quotecard {
  margin: 15px;
}

.quotecard__image {
  border-radius: 50%;
}

.header__footer {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-end;
}

@media (max-width: 1024px) {
  .header {
    flex: none;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    height: initial;
    padding: 3vw 6vw;
    background-color: var(--bg-normal);
  }

  .header__footer {
    display: none;
  }
  .header__intro {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;
  }
  .header__name {
    display: none;
    margin-bottom: 2px;
    line-height: 1;
  }
  .header__title {
    display: none;
  }

  .header__icon {
    flex-direction: row;
    display: flex;
  }

  .header__menu {
    display: block;
    cursor: pointer;
  }
  .nav {
    position: absolute;
    right: -100vw;
    top: 0;
    padding: 3vw 6vw;
    height: 350px;
    z-index: 1000;
    background-color: var(--bg-normal);
    opacity: 0;
    transition: all 1s ease-in-out;
    display: flex;
    justify-content: space-between;
  }

  .nav.open {
    opacity: 1;
    transform: translateX(-100vw);
  }

  .nav__close {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
  }
  .nav__closeIcon {
    transform: scale(1.5);
    cursor: pointer;
    border-radius: 25px;
  }
  .nav__closeIcon:hover {
    background-color: var(--bg-shade);
  }

  .nav__resume {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 10px 0;
    padding: 5px 7px;
    border-radius: 10px;
    color: var(--bg-normal);
    background-color: var(--text-stylish);
  }

  .nav__footer {
    margin: 10px;
  }
  .header > .footer {
    display: none;
  }
}
