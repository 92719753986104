.about__container {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 85%;
  padding: 3vw;
}

.about__container.active {
  display: flex;
}

.about {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  margin-bottom: 15px;
  overflow-y: visible;
}

.about__title {
  font-size: 30px;
}
.about__description {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: var(--font-stylish);
  font-weight: 500;
  padding: 50px;
  font-size: 2rem;
  font-style: bold;
  color: var(--text-normal);
  text-align: center;
}

.about__intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
}

.about__portrait {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 35px;
}

.about__portraitIcon {
  background-color: var(--text-stylish);
  border-radius: 50%;
  width: clamp(200px, 40vw, 250px);
  height: clamp(200px, 40vw, 250px);
}
.about__skills {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about__intro > h2 {
  font-weight: 500;
}

.about__intro > hr {
  border: solid 1px white;
  width: 320px;
}

.about__skills > h2 {
  font-weight: 500;
}

.about__skills > hr {
  border: solid 1px white;
  width: 180px;
}

.about__name {
  color: rgb(181, 255, 236);
  margin: 10px 0;
}

.about__resume {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 5px 7px;
  border-radius: 10px;
  color: var(--bg-normal);
  background-color: var(--text-stylish);
  /* margin-top: 10px; */
}

.about__resume:hover {
  filter: brightness(1.2);
}
.about__resumeIcon {
  margin-right: 5px;
}

.about__resumeText {
  font-size: clamp(14px, 1.5vw, 18px);
}
.only-mobile {
  display: none;
}

@media (max-width: 1024px) {
  .about__intro,
  ul,
  .about__resume,
  .about__skills > h3 {
    font-size: 80%;
  }
  .about__resumeLink {
    font-size: 100%;
  }
  .about__description {
    padding: 0;
  }
  .about__skills > hr {
    border: solid 1px white;
    width: 80px;
  }
  .about__container {
    display: flex;
    padding: 6vw;
    height: fit-content;
    margin-top: clamp(6vw, 20vw, 100px);
    margin-bottom: 10px;
  }
  .about {
    display: flex;
  }
  .about__portrait {
    margin-bottom: 15px;
  }
  .only-mobile {
    display: block;
  }

  .about__resume.only-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
