h1 {
  font-size: 3rem;
  font-weight: 600;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.75rem;
}

.casestudy {
  display: flex;
  flex-direction: column;
  background-color: #eeeeee;
}

.casestudy__header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 30px;
  background: #02041b;
  color: #fffaf8;
}
.casestudy__headerButton {
  display: flex;
  align-items: center;
}

.casestudy__headerButton:hover {
  color: rgb(255, 244, 125);
}

.casestudy__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
}
.casestudy__title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}
.casestudy__section {
  display: flex;
  width: 75%;
  background-color: #eeeeee;
  margin: 10px;
}
.casestudy__subsection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  padding: 10px;
}

.casestudy__subsection > div {
  margin: 10px;
}

.casestudy__subsection-tall {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 10px;
}

.casestudy__subsection-tall > div {
  margin: 10px;
}
.casestudy__image {
  width: 100%;
  max-height: 500px;
}
.casestudy__image-tall {
  width: 50%;
}
.casestudy__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 30px;
  background: #02041b;
}

.casestudy__footer__item {
  cursor: pointer;
  color: #fffaf8;
}

.casestudy__footer__item > a > .casestudy__footer__icon {
  opacity: 75%;
}
.casestudy__footer__item:not(:last-child) {
  margin-right: 30px;
}

.casestudy__footer__item:hover > a > .casestudy__footer__icon {
  opacity: 100%;
  color: rgb(255, 244, 125);
}

@media (max-width: 1024px) {
  .casestudy__title {
    margin: 15px;
  }
  .casestudy__section {
    flex-direction: column;
  }
}
